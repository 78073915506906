import { AsyncPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit,inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { ConfigService } from '@config/config.service';
import { BootstrapService } from '@core/service/bootstrap.service';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { HeaderBase } from 'app/layout/base/header.base';
import { HeaderItems } from 'app/layout/header/header-items';
import { LayoutService } from 'app/layout/services/layout.service';
import { MainSettingsModel } from 'app/website-settings/models/main-settings-model';
import { filter, takeUntil, map } from 'rxjs';

@Component({
  selector: 'app-header-public',
  standalone: true,
  imports: [AsyncPipe, NgClass, RouterLink, MatIconModule, NgbCollapse],
  templateUrl: './header-public.component.html',
  styleUrl: './header-public.component.scss'
})
export class HeaderPublicComponent extends HeaderBase implements OnInit, AfterViewInit, OnDestroy {

  WebsiteSetting :MainSettingsModel = inject(BootstrapService).WebsiteSetting;
  constructor(
    private configService: ConfigService,
    private router: Router,
    public layOutService: LayoutService
  ) {
    super();
    this.router.events.pipe(
      filter((events => events instanceof NavigationEnd)), takeUntil(this.Destroy$))
      .subscribe((event: NavigationEnd) => {
        this.currentURL = event?.url
      })

  }

  ngOnDestroy(): void {
    this.Destroy$?.next();
    this.Destroy$?.complete()
  }

  ngOnInit() {
    this.nav_items_init();
    this.config = this.configService.configData;
    this.FetchForums();
  }

  FetchForums() {
    this.ForumsList = this.layOutService.FetchForumsCatagoryList({}).pipe(map((items: any) => items?.Data))
  }

  ngAfterViewInit() {
    this.theme_init();
  }
  RemoveLocalStorageItems(Keys: Array<any>) {
    Keys.forEach((key) => localStorage.removeItem(key))
  }

  nav_items_init() {
    this.nav_items = HeaderItems.filter(item => this.WebsiteSetting[item.control])
    const dropdown_org_user_item = this.nav_items.find(e => e.hasOptions)
    if (dropdown_org_user_item) {
      if (this.WebsiteSetting.Organisation && this.WebsiteSetting.UserModule) {
        dropdown_org_user_item.class = "dropdown"
        return
      }
      if (this.WebsiteSetting?.Organisation) {
        dropdown_org_user_item.class = ""
        dropdown_org_user_item.path = '/search/organizations'
        return
      }
      if (this.WebsiteSetting?.UserModule) {
        dropdown_org_user_item.class = ""
        dropdown_org_user_item.path = '/search/users'
      }

    }


  }

}
