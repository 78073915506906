<nav #navbar class="navbar active">
      <div class="container-fluid">

            <div class="navbar-header collapse navbar-collapse">
                  <a class="navbar-toggle collapsed round btn rounded-circle" aria-label="collapse navbar"
                        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false">
                        <i class="fa fa-2x"
                              [ngClass]="isNavbarCollapsed ? ' fa-angle-double-down':'f fa-angle-double-up'"></i>
                  </a>
            </div>

            <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">

                  <ul class="nav navbar-nav navbar-right">
                        @for (item of nav_items; track $index) {
                        <li class="nav-item ms-1" [ngClass]="{'dropdown':item.class}">
                              <a href="#" onclick="return" class="nav-link show-cursor"
                                    [ngClass]="currentURL?.includes(item?.activeClass)? 'currentActive':'' "
                                    [routerLink]="item.path?item.path:null" routerLinkActive="router-link-active">
                                    {{WebsiteSetting[item?.controlCustomLabel? item?.controlCustomLabel:item?.control]}}
                              </a>

                              @if (item.class=='dropdown') {
                              @switch (item.control) {
                              @case ('Search') {
                              <div class="dropdown-content">
                                    @for (control of item.subControls; track $index) {
                                    <a href="#" onclick="return" [routerLink]="control.path"
                                          class="btn-link w-100 text-center">
                                          {{WebsiteSetting[control?.controlCustomLabel?
                                          control?.controlCustomLabel:control?.control]}}
                                    </a>
                                    }
                              </div>
                              }
                              @case ('Forums') {
                              <div class="dropdown-content">
                                    @for (forum of ForumsList | async; track $index) {
                                    <a href="#" onclick="return" class="btn-link w-100 show-cursor" aria-label="Forums"
                                          routerLink="/forums/general-forums"
                                          [queryParams]="{ForumCategory:forum?.ForumsCategoryId, ForumCategoryName:forum?.Name}">
                                          {{forum?.Name}}
                                    </a>
                                    }
                              </div>
                              }
                              }
                              }
                        </li>
                        }

                        @if (WebsiteSetting.SignUp) {
                        <li class="nav-item">
                              <a href="#" onclick="return" class="nav-link"
                                    [ngClass]="currentURL?.includes('registration')? 'currentActive':'' "
                                    routerLink="/registration" routerLinkActive="router-link-active">
                                    Sign Up
                              </a>
                        </li>
                        }

                        @if (!currentURL?.includes('signin')) {
                        <li class="nav-item">
                              <button class="btn rounded-circle" routerLink="/authentication/signin">
                                    <i class="fa fa-sign-in-alt"></i>
                              </button>
                        </li>
                        }

                  </ul>
            </div>
      </div>
</nav>