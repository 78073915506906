import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  visible:boolean = false;
  constructor(private http: HttpClient) { }

  FetchMainSetting(payload:any){
    return this.http.post(`${environment.ctUrl}Settings/FetchMainSettings`, payload);
  }
  FetchOrganizationDashbordCount(payload:any){
    return this.http.post(`${environment.ctUrl}Organization/FetchOrganizationDashboardCount`, payload);
  }
  FetchForumsList(payload:any){
    return this.http.post(`${environment.ctUrl}Menu/FetchGeneralForums`, payload);
  }
  FetchForumsCatagoryList(payload:any){
    return this.http.post(`${environment.ctUrl}Forum/FetchForumCategoryList`, payload);
  }
  FetchCountries(){
    return this.http.get(`${environment.ctUrl}Country/FetchCountries`);
  }

}
