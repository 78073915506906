import { Observable, Subject } from "rxjs";
import { IHeaderLink } from "../header/iheader-links";
import { Renderer2, inject } from "@angular/core";
import { BootstrapService } from "@core/service/bootstrap.service";
import { ConfigService } from "@config/config.service";

export class HeaderBase {
  public config= inject(ConfigService).configData;
  nav_items: Array<IHeaderLink> | [] = [];
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  isOpenSidebar: boolean = false;
  menuClosed: boolean = false;
  userName: string="";
  ForumsList: Observable<any>
  Destroy$: Subject<void> = new Subject();
  currentURL: string=""
  loggedIn: boolean = false;
  bootstrapService = inject(BootstrapService);
  renderer = inject(Renderer2);
  
  theme_init() {
    // set theme on startup
    if (localStorage.getItem('choose_skin')) {
      this.renderer.addClass(
        document.body,
        localStorage.getItem('choose_skin')
      );
      this.renderer.addClass(
        document.body, 'theme-' + localStorage.getItem('choose_skin_active'));
    } else {
      this.renderer.addClass(
        document.body,
        'theme-' + this.config.layout.theme_color
      );
      this.renderer.addClass(
        document.body,
        'theme-' + this.config.layout.theme_color);
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(document.body, 'side-closed');
        this.renderer.addClass(document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(document.body, 'side-closed');
        this.renderer.removeClass(document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(document.body, 'side-closed');
        this.renderer.addClass(document.body, 'submenu-closed');
      }
    }
    this.menuClosed = document.body.classList.contains('side-closed');
  }


  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(document.body, className);
    } else {
      this.renderer.addClass(document.body, className);
    }
  }
  callSidemenuCollapse() {
    const hasClass = document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(document.body, 'side-closed');
      this.menuClosed = false;
      this.renderer.removeClass(document.body, 'submenu-closed');
    } else {
      this.menuClosed = true;
      this.renderer.addClass(document.body, 'side-closed');
      this.renderer.addClass(document.body, 'submenu-closed');
    }
  }
}