import { IHeaderLink } from "./iheader-links";
export const HeaderItems: IHeaderLink[] = [
      {
            path: null,
            control: "Search",
            privacyControl: "SearchPrivacy",
            hasOptions:true,
            class:"",
            label:"",
            activeClass:"/search",
            controlCustomLabel: "SearchCustomLabel",
            subControls: [
                  {
                        path: "/search/organizations",
                        control: "Organisation",
                        label:"",
                        controlCustomLabel: "OrganisationCustomLabel"
                  },
                  {
                        path: "/search/users",
                        control: "UserModule",
                        label:"",
                        controlCustomLabel: "UserModuleLabel"
                  }
            ]
      },
      {
            path: "/opportunity/p-opportunity-listing",
            control: "Opportunity",
            label:"",
            privacyControl: "OpportunityPrivacy",
            activeClass:"/opportunity/p-opportunity-listing",
            controlCustomLabel: "OpportunityLabel"
      },
      {
            path: "/calendar/view",
            control: "CalenderOfEvents",
            label:"",
            controlCustomLabel: "COECustomLabel",
            privacyControl: "COEPrivacy",
            activeClass:"/calendar/view",
            subControls: [
                  {
                        path: "",
                        label:"",
                        control: "Posting",
                        controlCustomLabel: "PostingCustomLabel"
                  },
                  {
                        path: "",
                        label:"",
                        control: "Events",
                        controlCustomLabel: "EventsCustomLabel"
                  },
                  {
                        path: "",
                        label:"",
                        control: "Activities",
                        controlCustomLabel: "ActivitiesCustomLabel"
                  },
                  {
                        path: "",
                        label:"",
                        control: "Classes",
                        controlCustomLabel: "ClassesLabel"
                  }
            ]
      },
      {
            path: "/services/general-services",
            control: "Services",
            label:"",
            privacyControl: "ServicesPrivacy",
            activeClass:'/services/general-services',
            controlCustomLabel: "ServiceCustomLabel"
      },
      {
            path: "/donation/public-donations",
            control: "Donation",
            label:"",
            privacyControl: "DonationPrivacy",
            activeClass:"/donation/public-donations",
            controlCustomLabel: "DonationCustomLabel"
      },
      {
            path:"",
            control:"Forums",
            class:"dropdown",
            label:"",
            privacyControl:"ForumsPrivacy",
            activeClass:"/forums/general-forums",
            controlCustomLabel:"ForumsLabel"
      }
];

